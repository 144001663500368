import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-grid-system"
import { graphql } from "gatsby"
import styled from "styled-components"
import Masonry from "react-masonry-css"
import "src/components/core/layout/masonry.css"
import Layout from "src/components/core/layout/layout"
import PhotoTile from "src/components/photos/photo-tile"
import SEO from 'src/components/seo/SEO';

export const query = graphql`
  query PhotographyQuery {
      allPrismicProject {
        nodes {
            uid
          data {
            gallery {
              cover
              model {
                text
              }
              photo {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
            name {
              text
            }
          }
        }
      }
    }
  
`

export default ({ data }) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 3,
    500: 2,
  }


  const photos = []

  data.allPrismicProject.nodes.forEach(project=>
    {
        const el = project.data.gallery.some(photo=>photo.cover=="true") ? project.data.gallery.find(photo=>photo.cover=="true") : project.data.gallery[0]
        photos.push(<PhotoTile
        key={el.photo ? el.photo.fluid.src : null}
        photo={el.photo ? el.photo.fluid.src : null}
        name={el.model ? el.model.text : null}
        project={project.data.name ? project.data.name.text : null}
        link={project.uid}
        fluid={ el.photo ? el.photo.fluid.src : null}
        onContextMenu={console.log('hello')}
      />)
    })
  return (
      <>
      <SEO  title="Photography"/>
    <Layout>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {photos}
      </Masonry>
    </Layout>
    </>
  )
}
